import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Circle,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  Divider,
  Box,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import ReactSelect from 'react-select';

import './modal.css';
import { CloseIcon, OpenIcon } from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { AuditSheetContext } from '../../providers/AuditSheetProvider';
import CPTRecommendation from '../../pages/audit_sheet/components/CPTRecommendation';
import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';
import ICDRecommendation from '../../pages/audit_sheet/components/ICDRecommendation';

const fileTypeMap = {
  'application/CDFV2': 'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/vnd.ms-excel',
  'application/zip': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const FormViewer = ({ isOpen, onClose, currentFile, sheet, updateAuditSheet }) => {
  const auditSheetContext = useContext(AuditSheetContext);
  const { currentUpload, selectedRowCol, providerOptions, industryCodes, iCDCodes } = auditSheetContext;

  const [view, setView] = useState('form');
  const [encounterNumber, setEncounterNumber] = useState('');
  const [encounterDate, setEncounterDate] = useState('');

  const [billedCPTs, setBilledCPTs] = useState([]);
  const [billedModifiers, setBilledModifiers] = useState([]);
  const [billedUnits, setBilledUnits] = useState([]);
  const [billedICDs, setBilledICDs] = useState([]);
  const [billedRVU, setBilledRVU] = useState('');
  const [billedDollarValue, setBilledDollarValue] = useState('');

  const [auditedCPTs, setAuditedCPTs] = useState([]);
  const [auditedModifiers, setAuditedModifiers] = useState([]);
  const [auditedUnits, setAuditedUnits] = useState([]);
  const [auditedICDs, setAuditedICDs] = useState([]);
  const [auditedRVU, setAuditedRVU] = useState('');
  const [auditedDollarValue, setAuditedDollarValue] = useState('');

  const [response, setResponse] = useState('');
  const [agree, setAgree] = useState(true);
  const [icdAgree, setICDAgree] = useState(true);

  const [notes, setNotes] = useState('');
  const [providerEducation, setProviderEducation] = useState('');
  const [numberComplexity, setNumberComplexity] = useState('');
  const [amountComplexity, setAmountComplexity] = useState('');
  const [riskComplications, setRiskComplications] = useState('');

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isRowLoaded, setIsRowLoaded] = useState(false);

  const [formErrors, setFormErrors] = useState({
    encounterNumber: '',
    encounterDate: '',
  })

  const refsForBilledICD = useRef([]);
  const refsForAuditedICD = useRef([]);
  const refsForBilledCPT = useRef([]);
  const refsForAuditedCPT = useRef([]);

  const encounterNumberRef = useRef(null);
  const encounterDateRef = useRef(null);

  const formSchema = Yup.object().shape({
    encounterNumber: Yup.string()
      .required('Encounter Number is required')
      .test('is-alphanumeric', 'Encounter Number must be alphanumeric', (value) => {
        if (value) {
          return /^[a-zA-Z0-9]+$/.test(value);
        }
        return true;
      }),
    encounterDate: Yup.string()
      .nullable()
      .required('Encounter Date is required')
      .test('is-valid-date', 'Encounter Date must be a valid date', (value) => {
        if (!value) return false;
        const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
        return isValid(parsedDate);
      }),
  });


  React.useEffect(() => {
    if (selectedRowCol?.row !== undefined && sheet?.data?.[selectedRowCol.row]) {
      const rowData = sheet.data[selectedRowCol.row];
      setEncounterNumber(rowData.encounter_no || '');
      setEncounterDate(rowData.enc_dt ? convertToISODate(rowData.enc_dt) : "null");
      const matchingProvider = providerOptions.find((provider) => {
        if (!provider || !rowData) return false;
        const renderingId = rowData?.rendering?.id || null;
        const oldRenderingId = rowData?.old_rendering?.id || null;
        return provider.id === oldRenderingId || provider.id === renderingId;
      });
      setSelectedProvider(
        matchingProvider
          ? { value: matchingProvider.id, label: matchingProvider.value }
          : ""
      );

      const { cpts, units } = extractDynamicValues(rowData, 'srvcs_no');
      setBilledCPTs(cpts);
      setBilledUnits(units);

      const { cpts: acpts, units: aunits } = extractDynamicValues(rowData, 'audited_cpt');
      setAuditedCPTs(acpts);
      setAuditedUnits(aunits);

      handleAgreeDisagreeSelection(rowData);

      setBilledModifiers(extractDynamicModifierValues(rowData, 'billed_modifier'))
      setAuditedModifiers(extractDynamicModifierValues(rowData, 'audited_modifier'))

      setBilledICDs(extractDynamicICDValues(rowData, 'billed_icd'))
      setAuditedICDs(extractDynamicICDValues(rowData, 'audited_icd'))

      setIsRowLoaded(true);

      setBilledRVU(rowData.provider_rvu ?? '0.00');
      setBilledDollarValue(rowData.provider_dollar_value ?? '0.00');
      setAuditedRVU(rowData.audited_rvu ?? '0.00');
      setAuditedDollarValue(rowData.audited_dollar_value ?? '0.00');

      setResponse(rowData.response || '');
      setNotes(rowData.notes || '');
      setProviderEducation(rowData.provider_education || '');
      setRiskComplications(rowData.risk_complications || '');
      setNumberComplexity(rowData.number_complexity || '');
      setAmountComplexity(rowData.amount_complexity || '');

      setFormErrors({
        encounterNumber: '',
        encounterDate: '',
      });

    } else {
      clearForm();
      setIsRowLoaded(false);
    }
  }, [selectedRowCol, sheet]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        isOpen &&
        (e.key === 'ArrowDown' || 
         e.key === 'ArrowUp' || 
         e.key === 'ArrowLeft' || 
         e.key === 'ArrowRight' || 
         e.key === 'Tab' ||
         e.key === 'Enter'
        )
      ) {
        e.stopPropagation(); // Stop event from propagating to parent
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const clearForm = () => {
    setEncounterNumber('');
    setEncounterDate('');
    setBilledCPTs([]);
    setBilledModifiers([]);
    setBilledUnits([]);
    setBilledICDs([]);
    setAuditedCPTs([]);
    setAuditedModifiers([]);
    setAuditedUnits([]);
    setAuditedICDs([]);
    setSelectedProvider(null);
  };

  const convertToISODate = (dateString) => {
    if (!dateString) return null;
    const [month, day, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleAgreeDisagreeSelection = (rowData) => {
    if (rowData?.agree === true && rowData?.disagree === false) {
      setAgree(true);
    } else if (rowData?.agree === false && rowData?.disagree === true) {
      setAgree(false);
    } else if (rowData?.agree == null || rowData?.disagree == null) {
      let isEqual = true;
      let index = 0;

      while (
        (index === 0 && (rowData[`srvcs_no`] !== undefined || rowData[`audited_cpt`] !== undefined)) ||
        (index > 0 && (rowData[`srvcs_no_${index}`] !== undefined || rowData[`audited_cpt_${index}`] !== undefined))
      ) {
        const billedValue = index === 0 ? rowData[`srvcs_no`] : rowData[`srvcs_no_${index}`];
        const auditedValue = index === 0 ? rowData[`audited_cpt`] : rowData[`audited_cpt_${index}`];

        if (billedValue !== auditedValue) {
          isEqual = false;
          break;
        }
        index++;
      }

      setAgree(isEqual);
    }

    if (rowData?.icd_agree === true && rowData?.icd_disagree === false) {
      setICDAgree(true);
    } else if (rowData?.icd_agree === false && rowData?.icd_disagree === true) {
      setICDAgree(false);
    } else if (rowData?.icd_agree == null || rowData?.icd_disagree == null) {
      let isEqual = true;
      let index = 0;

      while (
        (index === 0 && (rowData[`billed_icd`] !== undefined || rowData[`audited_icd`] !== undefined)) ||
        (index > 0 && (rowData[`billed_icd_${index}`] !== undefined || rowData[`audited_cpt_${index}`] !== undefined))
      ) {
        const billedValue = index === 0 ? rowData[`billed_icd`] : rowData[`billed_icd_${index}`];
        const auditedValue = index === 0 ? rowData[`audited_icd`] : rowData[`audited_icd_${index}`];

        if (billedValue !== auditedValue) {
          isEqual = false;
          break;
        }
        index++;
      }

      setICDAgree(isEqual);
    }
  };


  const extractDynamicValues = (data, baseKey) => {
    if (!data || Object.values(data).every((value) => value === null || value === undefined)) {
      return { cpts: [], units: [] };
    }

    const cpts = [];
    const units = [];

    const extractCPTAndUnit = (value) => {
      if (!value) {
        return { cpt: '', unit: 1 };
      }
      if (typeof value === 'string' && value.includes('*')) {
        const [cpt, unit] = value.split('*');
        return { cpt: cpt.trim(), unit: parseInt(unit, 10) || 1 };
      }
      return { cpt: value.trim(), unit: 1 };
    };

    if (data?.[baseKey] !== undefined) {
      const { cpt, unit } = extractCPTAndUnit(data[baseKey]);
      cpts.push(cpt || '');
      units.push(unit);
    }

    let index = 1;
    while (data?.[`${baseKey}_${index}`] !== undefined) {
      const { cpt, unit } = extractCPTAndUnit(data[`${baseKey}_${index}`]);
      cpts.push(cpt || '');
      units.push(unit);
      index++;
    }

    while (cpts.length > 0 && cpts[cpts.length - 1] === '') {
      cpts.pop();
      units.pop();
    }

    return { cpts: cpts || [], units: units || [] };
  };

  const extractDynamicModifierValues = (data, baseKey) => {
    if (!data || Object.values(data).every((value) => value === null || value === undefined)) {
      return [];
    }

    const values = [];
    if (data?.[baseKey] !== undefined) {
      values.push(data[baseKey] || '');
    }

    let index = 1;
    while (data?.[`${baseKey}_${index}`] !== undefined) {
      values.push(data[`${baseKey}_${index}`] || '');
      index++;
    }

    while (values.length > 0 && values[values.length - 1] === '') {
      values.pop();
    }

    return values.map((value) => {
      if (typeof value === 'string' && value.includes(',')) {
        const [primary, secondary1, secondary2] = value.split(',').map((part) => part.trim());
        return {
          primary: primary || '',
          secondary1: secondary1 || '',
          secondary2: secondary2 || '',
        };
      }

      return {
        primary: value || '',
        secondary1: '',
        secondary2: '',
      };
    });
  };

  const extractDynamicICDValues = (data, baseKey) => {
    if (!data || Object.values(data).every((value) => value === null || value === undefined)) {
      return [];
    }

    const values = [];
    if (data?.[baseKey] !== undefined) {
      values.push(data[baseKey] || '');
    }

    let index = 1;
    while (data?.[`${baseKey}_${index}`] !== undefined) {
      values.push(data[`${baseKey}_${index}`] || '');
      index++;
    }

    while (values.length > 0 && values[values.length - 1] === '') {
      values.pop();
    }

    return values;
  };

  const formatDynamicCPTValues = (cptArray, unitArray, baseKey) => {
    const dynamicAttributes = {};

    cptArray.forEach((cpt, index) => {
      let formattedValue = cpt || null;

      const unit = unitArray && unitArray[index] ? unitArray[index] : 1;
      if (cpt && unit > 1) {
        formattedValue = `${cpt}*${unit}`;
      }

      if (index === 0) {
        dynamicAttributes[baseKey] = formattedValue;
      } else {
        dynamicAttributes[`${baseKey}_${index}`] = formattedValue;
      }
    });

    return dynamicAttributes;
  };

  const formatDynamicICDValues = (cptArray, baseKey) => {
    const dynamicAttributes = {};
    cptArray.forEach((cpt, index) => {
      let formattedValue = cpt || null;
      if (index === 0) {
        dynamicAttributes[baseKey] = formattedValue;
      } else {
        dynamicAttributes[`${baseKey}_${index}`] = formattedValue;
      }
    });
    return dynamicAttributes;
  };

  const formatDynamicModifierValues = (array, baseKey) => {
    const dynamicAttributes = {};

    array.forEach((value, index) => {
      let formattedValue = null;

      if (typeof value === "object" && value !== null) {
        const { primary, secondary1, secondary2 } = value;
        const parts = [primary, secondary1, secondary2].filter((part) => part?.trim() !== "");
        formattedValue = parts.length > 0 ? parts.join(",") : null;
      } else {
        formattedValue = value || null;
      }

      if (index === 0) {
        dynamicAttributes[baseKey] = formattedValue;
      } else {
        dynamicAttributes[`${baseKey}_${index}`] = formattedValue;
      }
    });

    return dynamicAttributes;
  };

  const resetDynamicKeys = (data, baseKey) => {
    if (!data) {
      return {};
    }
    const keysToReset = Object.keys(data).filter((key) => key.startsWith(baseKey));
    return keysToReset.reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {});
  };

  const handleSubmit = async () => {
    try {
      setFormErrors({
        encounterNumber: '',
        encounterDate: '',
      });

      const formData = {
        encounterNumber,
        encounterDate,
      };

      await formSchema.validate(formData, { abortEarly: false });

      const nextSheetData = [...(sheet?.data || [])];

      const resetSrvcsNo = resetDynamicKeys(nextSheetData[selectedRowCol.row], "srvcs_no");
      const resetBilledModifier = resetDynamicKeys(nextSheetData[selectedRowCol.row], "billed_modifier");
      const resetAuditedCpt = resetDynamicKeys(nextSheetData[selectedRowCol.row], "audited_cpt");
      const resetAuditedModifier = resetDynamicKeys(nextSheetData[selectedRowCol.row], "audited_modifier");
      const resetBilledIcd = resetDynamicKeys(nextSheetData[selectedRowCol.row], "billed_icd");
      const resetAuditedIcd = resetDynamicKeys(nextSheetData[selectedRowCol.row], "audited_icd");

      const billedcpt = formatDynamicCPTValues(billedCPTs, billedUnits, "srvcs_no");
      const billedmodifiers = formatDynamicModifierValues(billedModifiers, "billed_modifier");
      const auditedcpt = formatDynamicCPTValues(agree ? billedCPTs : auditedCPTs, agree ? billedUnits : auditedUnits, 'audited_cpt');
      const auditedmodifiers = formatDynamicModifierValues(agree ? billedModifiers : auditedModifiers, 'audited_modifier');
      const billedicd = formatDynamicICDValues(billedICDs, "billed_icd");
      const auditedicd = formatDynamicICDValues(icdAgree ? billedICDs : auditedICDs, "audited_icd");

      const finalBilledCPTValues = { ...resetSrvcsNo, ...billedcpt };
      const finalBilledModifierValues = { ...resetBilledModifier, ...billedmodifiers };
      const finalAuditedCPTValues = { ...resetAuditedCpt, ...auditedcpt };
      const finalAuditedModifierValues = { ...resetAuditedModifier, ...auditedmodifiers };
      const finalBilledICDValues = { ...resetBilledIcd, ...billedicd };
      const finalAuditedICDValues = { ...resetAuditedIcd, ...auditedicd };

      const finalAuditedRVU = agree ? billedRVU : auditedRVU;
      const finalAuditedDollarValue = agree ? billedDollarValue : auditedDollarValue;

      // Update the selected row if it exists, otherwise add a new row
      if (selectedRowCol?.row !== undefined && nextSheetData[selectedRowCol.row]) {
        nextSheetData[selectedRowCol.row] = {
          ...nextSheetData[selectedRowCol.row],
          file_name: currentUpload.upload_id,
          chart_id: currentUpload.id,
          encounter_no: encounterNumber,
          enc_dt: encounterDate,
          rendering: selectedProvider?.label || null,
          ...finalBilledCPTValues,
          ...finalAuditedCPTValues,
          ...finalBilledModifierValues,
          ...finalAuditedModifierValues,
          ...finalBilledICDValues,
          ...finalAuditedICDValues,
          provider_rvu: billedRVU,
          provider_dollar_value: billedDollarValue,
          audited_rvu: finalAuditedRVU,
          audited_dollar_value: finalAuditedDollarValue,
          agree: agree,
          disagree: !agree,
          icd_agree: icdAgree,
          icd_disagree: !icdAgree,
          response: response,
          notes: notes,
          provider_education: providerEducation,
          risk_complications: riskComplications,
          number_complexity: numberComplexity,
          amount_complexity: amountComplexity,
        };
      } else {
        nextSheetData.push({
          encounter_no: encounterNumber,
          rendering: selectedProvider?.label || null,
          enc_dt: encounterDate,
          ...billedcpt,
          ...auditedcpt,
          ...billedmodifiers,
          ...auditedmodifiers,
          ...billedicd,
          ...auditedicd,
          provider_rvu: billedRVU,
          provider_dollar_value: billedDollarValue,
          audited_rvu: finalAuditedRVU,
          audited_dollar_value: finalAuditedDollarValue,
          agree: agree,
          disagree: !agree,
          icd_agree: icdAgree,
          icd_disagree: !icdAgree,
          response: response,
          notes: notes,
          provider_education: providerEducation,
          risk_complications: riskComplications,
          number_complexity: numberComplexity,
          amount_complexity: amountComplexity,

          row_id: null,
          file_name: currentUpload.upload_id,
          chart_id: currentUpload.id,
        });
      }
      updateAuditSheet({ ...sheet, data: nextSheetData });
      setEncounterNumber('');
      onClose();

    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorDetails = {};
        let firstErrorField = null;

        error.inner.forEach((err) => {
          if (err.path === 'encounterNumber') {
            errorDetails.encounterNumber = err.message;
            if (!firstErrorField) firstErrorField = encounterNumberRef;
          }
          if (err.path === 'encounterDate') {
            errorDetails.encounterDate = err.message;
            if (!firstErrorField) firstErrorField = encounterDateRef;
          }
        });

        setFormErrors(errorDetails);

        // Scroll to the first field with an error
        if (firstErrorField && firstErrorField.current) {
          firstErrorField.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          firstErrorField.current.focus();
        }
      }
    }
  };

  const handleChange = async (field, value) => {
    if (field === 'encounterNumber') {
      setEncounterNumber(value);
    } else if (field === 'encounterDate') {
      setEncounterDate(value);
    }

    // Reset the error for that specific field first
    setFormErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));

    try {
      const fieldSchema = Yup.object().shape({
        [field]: formSchema.fields[field],
      });
      await fieldSchema.validate({ [field]: value });
      setFormErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setFormErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
      }
    }
  };

  const updateArray = (setter, array, index, value) => {
    const newArray = [...array];

    if (typeof value === "object") {
      newArray[index] = { ...newArray[index], ...value };
    } else {
      newArray[index] = value;
    }
    setter(newArray);
  };

  const removeArrayItem = (setter, array, index, refs) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    if (refs) {
      refs.current.splice(index, 1);
    }
    setter(newArray);
  };

  const addArrayItem = (setter, defaultValue, refs) => {
    setter((prev) => {
      const updatedArray = [...prev, defaultValue];
      if (refs) {
        setTimeout(() => {
          const lastIndex = updatedArray.length - 1;
          if (refs.current[lastIndex]) {
            refs.current[lastIndex].focus();
          }
        }, 0);
      }
      return updatedArray;
    });
  };

  const renderFile = () => {
    const docs = [
      {
        uri: currentFile?.preview_url,
        fileType: fileTypeMap[currentFile?.mime_type] ?? currentFile?.mime_type,
        fileName: ' ',
      },
    ];

    return <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} style={{ minHeight: 500 }} />;
  };

  const calculateTotalsForType = (cpts, modifiers, units, setRVU, setDollarValue) => {
    let totalRVU = 0;
    let totalDollarValue = 0;

    cpts.forEach((cpt, index) => {
      const modifier = modifiers[index]?.primary;
      const unit = units[index] || 1;

      const keyWithModifier = modifier ? `${cpt}-${modifier}` : cpt;
      const baseKey = cpt;

      const { rvu_value = 0, limiting_charge_amount = 0 } =
        industryCodes[keyWithModifier] || industryCodes[baseKey] || {};

      totalRVU += rvu_value * unit;
      totalDollarValue += limiting_charge_amount * unit;
    });

    setRVU(totalRVU.toFixed(2));
    setDollarValue(totalDollarValue.toFixed(2));
  };

  useEffect(() => {
    if (isRowLoaded) {
      setIsRowLoaded(false);
      return;
    }
    calculateTotalsForType(billedCPTs, billedModifiers, billedUnits, setBilledRVU, setBilledDollarValue);
  }, [billedCPTs, billedModifiers, billedUnits]);

  useEffect(() => {
    if (isRowLoaded) {
      setIsRowLoaded(false);
      return;
    }
    calculateTotalsForType(auditedCPTs, auditedModifiers, auditedUnits, setAuditedRVU, setAuditedDollarValue);
  }, [auditedCPTs, auditedModifiers, auditedUnits]);

  const renderDynamicICDFields = (label, array, setter, refs) => (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <SimpleGrid columns={3} spacing={2}>
        {array.map((icd, index) => (
          <HStack key={index}>
            <Box flex={1.3}>
              <ICDRecommendation
                icdCodes={iCDCodes}
                index={index}
                value={icd || ""}
                onSelect={(selectedKey) => {
                  const selectedICD = selectedKey;
                  updateArray(setter, array, index, selectedICD);
                }}
                inputRef={(ref) => {
                  refs.current[index] = ref;
                }}
              />
            </Box>
            <Button
              size="xs"
              colorScheme="red"
              onClick={() => removeArrayItem(setter, array, index, refs)}
            >
              -
            </Button>
          </HStack>
        ))}
      </SimpleGrid>
      <Button mt={array.length === 0 ? 1 : 2} size="sm" onClick={() => addArrayItem(setter, '', refs)}>
        Add
      </Button>
    </FormControl>
  );

  const renderDynamicCPTFields = (
    label,
    cptArray = [],
    modifierArray = [],
    unitArray = [],
    setCPTs,
    setModifiers,
    setUnits,
    refs
  ) => {
    return (
      <FormControl>
        <FormLabel fontWeight="">{label}</FormLabel>
        {cptArray.map((cpt, index) => (
          <VStack key={index} align="start" spacing={10} w="full" pb={1} >
            <HStack spacing={4} w="full">
              {/* CPT Field */}
              <Box flex={1.3}>
                <CPTRecommendation
                  industryCodes={industryCodes}
                  index={index}
                  value={cpt || ""}
                  onSelect={(selectedKey) => {
                    const [selectedCPT, selectedModifier] = selectedKey.split('-');
                    updateArray(setCPTs, cptArray, index, selectedCPT);
                    updateArray(setModifiers, modifierArray, index, { primary: selectedModifier || '' });
                  }}
                  inputRef={(ref) => {
                    refs.current[index] = ref;
                  }}
                />
              </Box>

              {/* Primary Modifier */}
              <Box flex={1}>
                <Input
                  placeholder={`Modifier ${index + 1} *`}
                  value={modifierArray[index]?.primary || ''}
                  onChange={(e) =>
                    updateArray(setModifiers, modifierArray, index, {
                      primary: e.target.value,
                    })
                  }
                />
              </Box>

              {/* Secondary Modifier 1 */}
              <Box flex={1}>
                <Input
                  placeholder={`Modifier ${index + 1}`}
                  value={modifierArray[index]?.secondary1 || ''}
                  onChange={(e) =>
                    updateArray(setModifiers, modifierArray, index, {
                      ...modifierArray[index],
                      secondary1: e.target.value,
                    })
                  }
                />
              </Box>

              {/* Secondary Modifier 2 */}
              <Box flex={1}>
                <Input
                  placeholder={`Modifier ${index + 1}`}
                  value={modifierArray[index]?.secondary2 || ''}
                  onChange={(e) =>
                    updateArray(setModifiers, modifierArray, index, {
                      ...modifierArray[index],
                      secondary2: e.target.value,
                    })
                  }
                />
              </Box>

              {/* Units Field */}
              <Box flex={0.5}>
                <Input
                  type="number"
                  placeholder="Units"
                  maxLength={2}
                  value={unitArray[index] === undefined ? 1 : unitArray[index]}
                  onChange={(e) => {
                    const value = e.target.value;
                    updateArray(
                      setUnits,
                      unitArray,
                      index,
                      value === '' ? '' : Math.min(parseInt(value, 10), 99)
                    );
                  }}
                />
              </Box>

              {/* Remove Button */}
              <Button
                size="xs"
                colorScheme="red"
                onClick={() => {
                  removeArrayItem(setCPTs, cptArray, index, refs);
                  removeArrayItem(setModifiers, modifierArray, index);
                  removeArrayItem(setUnits, unitArray, index);
                }}
              >
                -
              </Button>
            </HStack>
          </VStack>
        ))}

        {/* Add Button */}
        <Button
          mt={1}
          size="sm"
          onClick={() => {
            addArrayItem(setCPTs, '', refs);
            addArrayItem(setModifiers, { primary: '', secondary1: '', secondary2: '' });
            addArrayItem(setUnits, 1);
          }}
        >
          Add
        </Button>
      </FormControl>
    );
  };


  const renderForm = () => (
    <VStack spacing={6} p={4} align="stretch">

      <Text fontSize="lg" fontWeight="bold" color="black">{(sheet?.data?.[selectedRowCol?.row]?.id === null || Object.keys(selectedRowCol).length === 0) ? 'Enter Encounter Details' : 'Edit Encounter Details'}</Text>
      <SimpleGrid columns={2} spacing={4}>
        <FormControl isInvalid={!!formErrors.encounterNumber}>
          <FormLabel>Encounter Number</FormLabel>
          <Input
            ref={encounterNumberRef}
            placeholder="Enter Encounter Number"
            value={encounterNumber}
            onChange={(e) => handleChange('encounterNumber', e.target.value)}
          />
          {formErrors.encounterNumber && (
            <Text color="red.500" fontSize="sm" mt={1}>
              {formErrors.encounterNumber}
            </Text>
          )}
        </FormControl>
        <FormControl isInvalid={!!formErrors.encounterDate}>
          <FormLabel>Encounter Date</FormLabel>
          <Input
            ref={encounterDateRef}
            type="date"
            value={encounterDate}
            onChange={(e) => handleChange('encounterDate', e.target.value)}
          />
          {formErrors.encounterDate && (
            <Text color="red.500" fontSize="sm" mt={1}>
              {formErrors.encounterDate}
            </Text>
          )}
        </FormControl>
      </SimpleGrid>
      <FormControl>
        <FormLabel>Provider (Rendering)</FormLabel>
        <ReactSelect
          placeholder="Select Provider"
          options={providerOptions.map((provider) => ({
            value: provider.id,
            label: provider.value
          }))}
          value={selectedProvider}
          onChange={(selectedOption) => setSelectedProvider(selectedOption || null)}
          isSearchable
          noOptionsMessage={() => 'No Providers Found'}
        />
      </FormControl>
      <Divider my={4} />
      <Text fontSize="md" fontWeight="bold" mt={4}>
        Billed Details
      </Text>
      {renderDynamicCPTFields('Billed CPT and Modifier', billedCPTs, billedModifiers, billedUnits, setBilledCPTs, setBilledModifiers, setBilledUnits, refsForBilledCPT)}
      <SimpleGrid columns={2} spacing={4}>
        <FormControl>
          <FormLabel>Provider RVU</FormLabel>
          <Input value={billedRVU} onChange={(e) => setBilledRVU(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Billed Dollar Value</FormLabel>
          <InputGroup>
            <InputLeftAddon children="$" />
            <Input
              value={billedDollarValue}
              onChange={(e) => setBilledDollarValue(e.target.value)}
              placeholder="Enter amount"
            />
          </InputGroup>
        </FormControl>
      </SimpleGrid>
      {renderDynamicICDFields('Billed ICD', billedICDs, setBilledICDs, refsForBilledICD)}
      <Divider my={4} />
      <Text fontSize="md" fontWeight="bold" mt={4}>
        Validation Details
      </Text>
      <FormControl>
        <FormLabel>Response</FormLabel>
        <Input value={response} onChange={(e) => setResponse(e.target.value)} />
      </FormControl>
      <SimpleGrid columns={2} spacing={4}>
        <FormControl>
          <FormLabel>CPT Agree</FormLabel>
          <HStack>
            <Button
              size="sm"
              colorScheme={agree ? 'green' : 'gray'}
              onClick={() => setAgree(true)}
            >
              Agree
            </Button>
            <Button
              size="sm"
              colorScheme={!agree ? 'red' : 'gray'}
              onClick={() => setAgree(false)}
            >
              Disagree
            </Button>
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel>ICD Agree</FormLabel>
          <HStack>
            <Button
              size="sm"
              colorScheme={icdAgree ? 'green' : 'gray'}
              onClick={() => setICDAgree(true)}
            >
              Agree
            </Button>
            <Button
              size="sm"
              colorScheme={!icdAgree ? 'red' : 'gray'}
              onClick={() => setICDAgree(false)}
            >
              Disagree
            </Button>
          </HStack>
        </FormControl>
      </SimpleGrid>
      <Divider my={4} />
      {!(agree && icdAgree) && (
        <>
          <Text fontSize="md" fontWeight="bold" mt={4}>
            Audited Details
          </Text>
          <VStack spacing={4} align="stretch">

            {!agree && (
              <>
                {renderDynamicCPTFields(
                  'Audited CPT and Modifier',
                  auditedCPTs,
                  auditedModifiers,
                  auditedUnits,
                  setAuditedCPTs,
                  setAuditedModifiers,
                  setAuditedUnits,
                  refsForAuditedCPT
                )}
                <SimpleGrid columns={2} spacing={4}>
                  <FormControl>
                    <FormLabel>Audited RVU</FormLabel>
                    <Input
                      value={auditedRVU}
                      onChange={(e) => setAuditedRVU(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Audited Dollar Value</FormLabel>
                    <InputGroup>
                      <InputLeftAddon children="$" />
                      <Input
                        value={auditedDollarValue}
                        onChange={(e) => setAuditedDollarValue(e.target.value)}
                        placeholder="Enter amount"
                      />
                    </InputGroup>
                  </FormControl>
                </SimpleGrid>
              </>
            )}
            {!icdAgree &&
              renderDynamicICDFields('Audited ICD', auditedICDs, setAuditedICDs, refsForAuditedICD)}
          </VStack>
        </>
      )}

      {!(agree && icdAgree) && <Divider my={4} />}
      <Text fontSize="md" fontWeight="bold">Additional Details</Text>
      <FormControl>
        <FormLabel>Notes</FormLabel>
        <Input value={notes} onChange={(e) => setNotes(e.target.value)} />
      </FormControl>
      <SimpleGrid columns={2} spacing={4}>
        <FormControl>
          <FormLabel>Provider Education</FormLabel>
          <Input value={providerEducation} onChange={(e) => setProviderEducation(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Risk Complications</FormLabel>
          <Input value={riskComplications} onChange={(e) => setRiskComplications(e.target.value)} />
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={2} spacing={4}>
        <FormControl>
          <FormLabel>Number Complexity</FormLabel>
          <Input value={numberComplexity} onChange={(e) => setNumberComplexity(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Amount Complexity</FormLabel>
          <Input value={amountComplexity} onChange={(e) => setAmountComplexity(e.target.value)} />
        </FormControl>
      </SimpleGrid>

      <Button colorScheme="blue" size="md" alignSelf="center" onClick={handleSubmit}>
        {(sheet?.data?.[selectedRowCol?.row]?.id === null || Object.keys(selectedRowCol).length === 0) ? 'Add' : 'Update'}
      </Button>
    </VStack>
  );

  return (
    <Modal size={'xl'} onClose={onClose} isOpen={isOpen} scrollBehavior={'inside'}>
      <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
      <ModalContent
        style={{
          background: 'white',
          boxShadow: 'none',
          maxWidth: '60%',
          maxHeight: '80vh',
          overflowY: 'auto',
          borderRadius: '8px',
          padding: '16px',
        }}
      >
        <Box
          position="sticky"
          top="-4"
          bg="white"
          zIndex={1}
          boxShadow="sm"
          p={2}
        >
          <HStack>
            <Text fontSize="md" color="black">
              {currentFile?.name.split('_').join(' ')}
            </Text>
            {currentFile?.mime_type === 'application/pdf' ? <Circle
              size="30px"
              bg={AppColors.gray}
              color={AppColors.gray}
              onClick={() => {
                window.open(currentFile?.preview_url, '_blank');
              }}
              style={{ cursor: 'pointer' }}
            >
              <OpenIcon />
            </Circle> : null}
            <Spacer />
            <Button
              size="sm"
              colorScheme="teal"
              onClick={() => setView(view === 'file' ? 'form' : 'file')}
            >
              {view === 'file' ? 'Switch to Form' : 'Switch to File'}
            </Button>
            <CloseIcon
              style={{ cursor: 'pointer', height: '30px', width: '30px' }}
              onClick={onClose}
            />
          </HStack>
        </Box>
        {view === 'file' ? renderFile() : renderForm()}
      </ModalContent>
    </Modal>

  );
};

export default FormViewer;