import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Box, Input, List, ListItem, FormControl, Tooltip } from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';

const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
};

const ICDRecommendation = ({ icdCodes, index, onSelect, value, inputRef }) => {
    const [query, setQuery] = useState(value || '');
    const [recommendations, setRecommendations] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setQuery(value || '');
    }, [value]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        // When dropdown is shown and has recommendations, set the first item as the focused one
        if (showDropdown && recommendations.length > 0 && focusedIndex === -1) {
            setFocusedIndex(0);
        }
    }, [showDropdown, recommendations]);

    const handleSearch = useMemo(
        () =>
            debounce((value) => {
                if (!value) {
                    setRecommendations([]);
                    setShowDropdown(false);
                    return;
                }

                const filtered = Object.keys(icdCodes)
                    .filter((key) => key.toLowerCase().startsWith(value.toLowerCase()))
                    .slice(0, 10); // Limit to top 10 recommendations

                setRecommendations(filtered);
                setShowDropdown(true);
            }, 300), // 300ms debounce delay
        [icdCodes]
    );

    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        setFocusedIndex(-1);
        handleSearch(value);
    };

    const handleSelect = (icd) => {
        setQuery(icd);
        setShowDropdown(false);
        if (onSelect) onSelect(icd);
    };

    // Handle blur event triggered when an element loses focus
    const handleBlur = () => {
        if (query === value) {
            return;
        }
        setQuery(query);
        if (onSelect) onSelect(query);
    };

    const handleKeyDown = (e) => {
        if (!showDropdown || recommendations.length === 0) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setFocusedIndex((prevIndex) =>
                    prevIndex < recommendations.length - 1 ? prevIndex + 1 : 0
                );
                break;

            case 'ArrowUp':
                e.preventDefault();
                setFocusedIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : recommendations.length - 1
                );
                break;

            case 'Enter':
                e.preventDefault();
                if (focusedIndex >= 0 && focusedIndex < recommendations.length) {
                    handleSelect(recommendations[focusedIndex]);
                }
                break;
            case 'Tab':
                setShowDropdown(false);
                break;

            default:
                break;
        }
    };

    return (
        <FormControl>
            <Box position="relative" w="full" ref={dropdownRef}>
                <Tooltip
                    label={icdCodes[query.toUpperCase()]?.description}
                    placement="auto"
                    bg="whiteAlpha.900"
                    color="black"
                    border="1px solid"
                    borderColor={AppColors.primary}
                    fontSize="xs"
                    borderRadius="md"
                >
                    <Input
                        ref={inputRef}
                        value={query}
                        onChange={handleInputChange}
                        placeholder={`ICD Code ${index + 1}`}
                        onFocus={() => query && recommendations.length > 0 && setShowDropdown(true)}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                    />
                </Tooltip>

                {showDropdown && recommendations.length > 0 && (
                    <Box
                        position="absolute"
                        bg="white"
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        mt={1}
                        zIndex={10}
                        maxH="200px"
                        overflowY="auto"
                        width="70%"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '3px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                            },
                        }}
                    >
                        <List spacing={1}>
                            {recommendations.map((icd, index) => (
                                <Tooltip
                                    key={icd}
                                    label={icdCodes[icd]?.description}
                                    placement="auto"
                                    bg="whiteAlpha.900"
                                    color="black"
                                    border="1px solid"
                                    borderColor={AppColors.primary}
                                    fontSize="xs"
                                    borderRadius="md"
                                    offset={[10, 0]}
                                >
                                    <ListItem
                                        ref={(el) => {
                                            if (focusedIndex === index && el) {
                                                el.scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'nearest',
                                                });
                                            }
                                        }}
                                        cursor="pointer"
                                        p={2}
                                        bg={focusedIndex === index || recommendations.length === 1 ? 'gray.100' : 'white'}
                                        _hover={{
                                            bg: 'gray.100',
                                            transition: 'all 0.1s ease-in-out',
                                        }}
                                        onClick={() => handleSelect(icd)}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        borderRadius="8px"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        m={1}
                                    >
                                        <Box display="flex" flexDirection="column">
                                            <Box fontWeight="bold" fontSize="sm">
                                                {icd}
                                            </Box>
                                        </Box>
                                    </ListItem>
                                </Tooltip>
                            ))}
                        </List>
                    </Box>
                )}
            </Box>
        </FormControl>
    );
};

export default ICDRecommendation;
