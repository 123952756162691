import { Circle } from '@chakra-ui/react';
import React, { useContext } from 'react';
import FormViewer from '../../components/pdf_viewer/FormViewer';
import { AuditSheetContext } from '../../providers/AuditSheetProvider';
import { AddIcon, EditIcon } from '@chakra-ui/icons';

const AuditSheetFormViewer = ({ auditData, sheet, updateAuditSheet }) => {
  const auditSheetContext = useContext(AuditSheetContext);

  return (
    <React.Fragment>
      {(sheet?.data?.[auditSheetContext.selectedRowCol?.row]?.id === null || Object.keys(auditSheetContext.selectedRowCol).length === 0) ? (
        <Circle
          size="40px"
          bg="#416cae"
          color="white"
          onClick={auditSheetContext.setIsAuditFormOpen.on}
          cursor="pointer"
          _hover={{
            bg: "#547ab6",
          }}
          transition="all 0.2s ease-in-out"
        >
          <AddIcon w={5} h={5} strokeWidth="2" />
        </Circle>

      ) : (
        <Circle
          size="40px"
          bg="#416cae"
          color="white"
          onClick={auditSheetContext.setIsAuditFormOpen.on}
          cursor="pointer"
          _hover={{
            bg: "#547ab6",
          }}
          transition="all 0.2s ease-in-out"
        >
          <EditIcon w={5} h={5} strokeWidth="2" />
        </Circle>
      )}
      <FormViewer
        isOpen={auditSheetContext.isAuditFormOpen}
        currentFile={auditData?.file_obj}
        onClose={auditSheetContext.setIsAuditFormOpen.off}
        sheet={sheet}
        updateAuditSheet={updateAuditSheet}
      />
    </React.Fragment>
  );
};

export default AuditSheetFormViewer;
